import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask} from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {Router} from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './edit.user.component.html',
  styleUrls: ['./edit.user.component.css']
})
export class EditUserComponent{
  dbUser;
  currentUser;
  db;
  userId;

  constructor(angularFireDatabase: AngularFireDatabase, public afAuth: AngularFireAuth, public afStorage: AngularFireStorage,
    public router: Router, private _Activatedroute: ActivatedRoute) {

    this.userId = this._Activatedroute.snapshot.paramMap.get("userId");
    console.log('userId', this.userId)

    this.db = angularFireDatabase;
      this.db.object('/staging/users/' + this.userId)
        .snapshotChanges()
        .subscribe(user => {
          this.currentUser = user.payload.val();
          if (!this.currentUser['userType']) { this.currentUser['userType'] = '' }
          if (!this.currentUser['userRole']) { this.currentUser['userRole'] = '' }
        });
  }

  saveUserData(){
    // console.log('[saveUserData] this.userId', this.userId)
    // console.log('[saveUserData] this.currentUser', this.currentUser)
    this.db.object('/staging/users/' + this.userId).update(
      this.currentUser
    )
    .then(
      (res)=>{
        alert('User data saved');
        console.log('[Edit user]  Updated data ', res )
      }
    )
    .catch( (err) => {
           alert('Unable to save user data');
           console.log('err', err)
         } );
  }





}
