import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule, MatIconModule, MatFormFieldModule, MatInputModule,MatRippleModule, MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule }    from '@angular/common/http';
// import { ImageUploadModule } from "angular2-image-upload";

import {CalendarModule} from 'primeng/components/calendar/calendar';
import { ChartsModule } from 'ng2-charts';


import { AppComponent } from './app.component';
import { routing } from './app.routes';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { SubcategoriesComponent } from './subcategories/subcategories.component';
import { AddSubcategoryComponent } from './add-subcategory/add-subcategory.component';
import { EditSubcategoryComponent } from './edit-subcategory/edit-subcategory.component';
import { QuestionsComponent } from './questions/questions.component';
import { AddQuestionComponent } from './add-question/add-question.component';
import { EditQuestionComponent } from './edit-question/edit-question.component';
import { ViewQuestionComponent } from './view-question/view-question.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { UsersComponent } from './users/users.component';
import { EditUserComponent } from './users/edit-user/edit.user.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { TournamentsComponent } from './tournaments/tournaments.component';
import { QuizesComponent } from './quizes/quizes.component';
import { AddQuizComponent } from './quizes/add-quiz/add-quiz.component';
import { AddTournamentComponent } from './tournaments/add-tournament/add-tournament.component';
import { EditTournamentComponent } from './tournaments/edit-tournament/edit-tournament.component';
import { TopMenuComponent } from './menus/top-menu/top-menu.component';
import { AppReportsComponent } from './app-reports/app.reports.component';
import { OrganisationStatisticsComponent } from './organisation-statistics/organisation.statistics.component';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    SubcategoriesComponent,
    QuizesComponent,
    AddQuizComponent,
    TournamentsComponent,
    TransactionsComponent,
    AddSubcategoryComponent,
    EditSubcategoryComponent,
    QuestionsComponent,
    AddQuestionComponent,
    EditQuestionComponent,
    ViewQuestionComponent,
    MyProfileComponent,
    UsersComponent,
    EditUserComponent,
    AddTournamentComponent,
    EditTournamentComponent,
    TopMenuComponent,
    AppReportsComponent,
    OrganisationStatisticsComponent
  ],
  imports: [
    BrowserModule,
    routing,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule, MatIconModule, MatFormFieldModule, MatInputModule, MatRippleModule,
    MatDatepickerModule, MatNativeDateModule,
    CalendarModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule, // imports firebase/database, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    AngularFireStorageModule,
    ChartsModule
    // ImageUploadModule.forRoot()
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  entryComponents:[
    AddSubcategoryComponent,
    EditSubcategoryComponent,
    AddQuestionComponent,
    EditQuestionComponent,
    ViewQuestionComponent,
    MyProfileComponent,
    UsersComponent,
    AddTournamentComponent,
    AppReportsComponent,
    OrganisationStatisticsComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
