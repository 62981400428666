import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask} from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {Router} from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  currentUserId;
  dbUser;
  currentUser;
  db;
  users=[];
  displayNameFilter='';
  roleFilter;
  typeFilter;
  nameFilter;

  constructor(fb: FormBuilder, angularFireDatabase: AngularFireDatabase, public afAuth: AngularFireAuth, public afStorage: AngularFireStorage,
    public router: Router) {
    this.db = angularFireDatabase;
    this.afAuth.authState.subscribe(res => {
      // console.log('Users] [ngOnInit]', res);
      if (res) {
        this.currentUserId = res.uid;
        this.dbUser = this.db.object('/staging/users/' + res.uid);
        this.dbUser.snapshotChanges()
          .subscribe(user => {
            this.currentUser = user.payload.val();
            // console.log('My profile] [ngOnInit] this.currentUser', this.currentUser);
          });
      }
      else {
        console.log('!!!user not logged in!!!!');
        this.router.navigate(['/login']);
      }
  });

  }

  ngOnInit() {
    this.db.list('/staging/users/')
    .snapshotChanges()
    .subscribe(res => {
      this.users = res.map(
        res.forEach(user => {
          let temp = user.payload.val()
          temp['key'] = user['key']
          this.users.push(temp)

        })
      )
    });

  }

  filterByDisplayName(){
    this.users.forEach(
      (user) => {
      if ( user.displayName.toLowerCase().includes(this.nameFilter.toLowerCase()) ||
          (user.email && user.email.toLowerCase().includes(this.nameFilter.toLowerCase()) )
      ) {
            user['show'] = true
      }
      else{
        user['show'] = false
      }
    });
  }

  filterByType(){
    this.users.forEach(
          user => {
          if (user.userType && user.userType === this.typeFilter) {
            user['show'] = true
          }
          else{
            user['show'] = false
          }
        });
  }


}
