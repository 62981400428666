import { Component, OnInit, ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';

export class Option{
	score: number;
	title: string;
}
export class Question{
	title: string;
	options: any[];
}

@Component({
  selector: 'app-add-question',
  templateUrl: './add-question.component.html',
  styleUrls: ['./add-question.component.css']
})

export class AddQuestionComponent implements OnInit {
	questionForm : FormGroup;
	question:Question;
	checkedValues=[false, false, false, false];

  	constructor(public dialogRef: MatDialogRef <AddQuestionComponent>, fb: FormBuilder) {
  		//form validation rulles
  		this.questionForm = fb.group({
			'title': [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(200), this.WhitespaceValidator])],
			'q1Title': [null, Validators.compose([Validators.required, this.WhitespaceValidator])],
			'q2Title': [null, Validators.compose([Validators.required, this.WhitespaceValidator])],
			'q3Title': [null, Validators.compose([Validators.required, this.WhitespaceValidator])],
			'q4Title': [null, Validators.compose([Validators.required, this.WhitespaceValidator])],
			'scores': fb.group({
				'q1Score': [null],
				'q2Score': [null],
				'q3Score': [null],
				'q4Score': [null],
			}, {validator: this.AtLeastOneCheckboxValidator})
	    })
	}

	ngOnInit() {
	}

	saveData() {
		let res = this.questionForm.value;
		var ds: Question;
		res.scores.q1Score = res.scores.q1Score ? 1 : 0;
		res.scores.q2Score = res.scores.q2Score ? 1 : 0;
		res.scores.q3Score = res.scores.q3Score ? 1 : 0;
		res.scores.q4Score = res.scores.q4Score ? 1 : 0;
		ds = {'title': res.title, options:[{'title': res.q1Title, 'score': res.scores.q1Score},
											{'title': res.q2Title, 'score': res.scores.q2Score},
											{'title': res.q3Title, 'score': res.scores.q3Score},
											{'title': res.q4Title, 'score': res.scores.q4Score}
											]};
	    this.dialogRef.close(ds);
	}

	checkboxChecked(nr){
		console.log('checked ', nr);
		for (var i = 0; i <= 3; i++) {
			if (i != nr){
				this.checkedValues[i]=false;
			}
		}
	}

	public WhitespaceValidator(control: FormControl) {
		if (((control.value || '').trim().length === 0) ||( (control.value || '').charAt(0) === ' ') ) {
			// contains only whitespace or starts with a space, so we invalidate it
		    return { 'whitespace': true };
		}
		else{
			console.log('no Whitespace', (control.value || '').charAt(0));
			return null;
		}
	}

	public AtLeastOneCheckboxValidator(group: FormGroup) {
		console.log('validating group');
		let isAtLeastOne = false;
		if (group && group.controls) {
		    for (const control in group.controls) {
		      if (group.controls.hasOwnProperty(control) && group.controls[control].valid && group.controls[control].value) {
		        isAtLeastOne = true;
		        break;
		      }
		    }
		  }
		console.log('isAtLeastOne', isAtLeastOne);
		return isAtLeastOne ? null : { 'required': true };
	}

}
