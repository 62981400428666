import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask} from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {Router, ActivatedRoute} from '@angular/router';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import * as moment from 'moment';

@Component({
  selector: 'app-reports-component',
  templateUrl: './app.reports.component.html',
  styleUrls: ['./app.reports.component.css']
})
export class AppReportsComponent{
  selectedSection;
  currentUserId;
  dbUser;
  currentUser;
  db;
  userId;
  startDate;
  endDate;
  tournamentsStartDate;
  tournamentsEndDate
  withdrawalsStartDate
  withdrawalsEndDate
  paymentsStartDate
  paymentsEndDate
  tournamentsActions = []
  paymentsActions = []
  withdrawalsActions = []
  actions = [];
  tournamentsTotals
  financialsTotals

  // public lineChartData = [
  //   { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
  //   { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' },
  //   { data: [180, 480, 770, 90, 1000, 270, 400], label: 'Series C', yAxisID: 'y-axis-1' }
  // ];
  public lineChartData = [
  ];
  // public lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  public lineChartLabels=[];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
        },
        {
          id: 'y-axis-1',
          position: 'right',
          gridLines: {
            color: 'rgba(255,0,0,0.3)',
          },
          ticks: {
            fontColor: 'red',
          }
        }
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
    { // grey
      backgroundColor: 'rgba(158,201,75,0.2)',
      borderColor: 'rgba(158,201,75,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // red
      backgroundColor: 'rgba(255,0,0,0.3)',
      borderColor: 'red',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // dark grey
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    }

  ];
  public lineChartLegend = true;
  public lineChartType = 'line';

  constructor(angularFireDatabase: AngularFireDatabase, public afAuth: AngularFireAuth, public afStorage: AngularFireStorage,
    public router: Router, private _Activatedroute: ActivatedRoute) {

    this.userId = this._Activatedroute.snapshot.paramMap.get("userId");
    console.log('userId', this.userId)

    this.db = angularFireDatabase;
      this.db.object('/staging/users/' + this.userId)
        .snapshotChanges()
        .subscribe(user => {
          this.currentUser = user.payload.val();
        });
  }

  getHistory(){
    // console.log('Dates:' , this.startDate.getTime(), this.endDate)
    if (!this.endDate) {
      this.endDate = new Date();
    }
    let actionsEndDate = new Date();
    actionsEndDate.setHours(23,59,59,0);

    this.db.list('/staging/actionsHistory/',
        ref => {
          return ref.orderByChild('date_time')
          .startAt( this.startDate.getTime() )
          .endAt( actionsEndDate.getTime() )
        }
    )
    .valueChanges()
      .subscribe(
        (res)=>{
          console.log('[AppReports] [getHistory] Got data: ', res)
          this.actions = res;
          this.actions.forEach(
            (action)=>{
              if(action.date_time){
                action.date_time = new Date( Number(action.date_time))
              }
              // get Tournament Info
              if(action.tournamentId){
                this.db.object('/staging/tournamentsInfo/' + action.tournamentId
                  )
                  .valueChanges()
                    .subscribe(
                      (res)=>{
                     action['tournament'] = res
                      },
                      (err)=>{
                        console.log('[AppReports] [getTournamentInfo] Error retrieving data: ', err)
                      }
                    )
              }

              // get User Info
              if(action.userId){
                this.db.object('/staging/users/' + action.userId
                  )
                  .valueChanges()
                    .subscribe(
                      (res)=>{
                        if(res){
                          action['user'] = res
                        }
                        else{
                          action['user'] = {displayName:'invalid user'}
                        }
                      },
                      (err)=>{
                        console.log('[AppReports] [getUserInfo] Error retrieving data: ', err)
                      }
                    )
              }

            }
          )
        },
        (err)=>{
          console.log('[AppReports] [getHistory] Error retrieving data: ', err)
        }
      )
  }

  getTournamentsHistory(){
    // console.log('Dates:' , this.startDate.getTime(), this.endDate)
    let endDate = new Date();
    endDate.setHours(23,59,59,0);

    this.db.list('/staging/actionsHistory/',
        ref => {
          return ref.orderByChild('date_time')
          .startAt( this.tournamentsStartDate.getTime() )
          .endAt( endDate.getTime() )
        }
    )
    .valueChanges()
      .subscribe(
        (res)=>{

          this.tournamentsActions = [];
          res.forEach(element => {
            if (element['action'] == 'EndTournament') {
              this.tournamentsActions.push(element)
            }
          });

          this.tournamentsTotals ={'markers_played':0, 'host_winnings':0, 'winners_prizes':0, 'zenith_winnings':0 }
          this.tournamentsActions.forEach(
            (action)=>{
              if(action.date_time){
                action.date_time = new Date( Number(action.date_time))
              }
              // get Tournament Info
              if(action.tournamentId){
                this.db.object('/staging/tournamentsInfo/' + action.tournamentId
                  )
                  .valueChanges()
                    .subscribe(
                      (res)=>{
                        action['tournament'] = res
                        this.tournamentsTotals.markers_played += res.results.totalMarkers
                        this.tournamentsTotals.host_winnings += res.results.hostMarkers
                        this.tournamentsTotals.winners_prizes += res.results.winnerMarkers
                        this.tournamentsTotals.zenith_winnings = this.tournamentsTotals.markers_played - this.tournamentsTotals.host_winnings - this.tournamentsTotals.winners_prizes
                      },
                      (err)=>{
                        console.log('[AppReports] [getTournamentInfo] Error retrieving data: ', err)
                      }
                    )
              }

              // get User Info
              if(action.userId){
                this.db.object('/staging/users/' + action.userId
                  )
                  .valueChanges()
                    .subscribe(
                      (res)=>{
                        if(res){
                          action['user'] = res
                        }
                        else{
                          action['user'] = {displayName:'invalid user'}
                        }
                      },
                      (err)=>{
                        console.log('[AppReports] [getUserInfo] Error retrieving data: ', err)
                      }
                    )
              }
              else{

              }

            }
          )
          console.log('[AppReports] [getTournamentsHistory] Tournaments: ', this.tournamentsActions)
        },
        (err)=>{
          console.log('[AppReports] [getHistory] Error retrieving data: ', err)
        }
      )

  }

  getPaymentsHistory(){
    // console.log('Dates:' , this.startDate.getTime(), this.endDate)
    if (!this.paymentsEndDate) {
      this.paymentsEndDate = new Date();
    }
    let endDate = new Date();
    endDate.setHours(23,59,59,0);

    this.db.list('/staging/actionsHistory/',
        ref => {
          return ref.orderByChild('date_time')
          .startAt( this.paymentsStartDate.getTime() )
          .endAt( endDate.getTime() )
        }
    )
    .valueChanges()
      .subscribe(
        (res)=>{
          // console.log('[AppReports] [getPaymentsHistory] Got data: ', res)
          this.lineChartData=[]
          this.lineChartLabels =[];
          this.paymentsActions = [];
          res.forEach(element => {
            if (element['action'] === 'Purchase' ||  element['action'] === 'SellRequest') {
              this.paymentsActions.push(element)
            }
          });

          let chartData=[];
          this.financialsTotals = {purchases:0, SellRequests: 0}
           this.paymentsActions.forEach(
            (action)=>{
              if(action.date_time){
                action.date_time = new Date( Number(action.date_time))
              }

              // get Transaction Info
              if(action.transactionId){
                this.db.object('/staging/transactionsHistory/' + action.userId + '/' + action.transactionId
                  )
                  .valueChanges()
                    .subscribe(
                      (res)=>{
                      action['transaction'] = res
                      const tempDate = new Date( Number(res.date_time))
                      const tempDateString = moment(tempDate).format('DD-M-YY')
                      if (action['action'] === 'SellRequest') {
                        this.financialsTotals.SellRequests += Number(res.value)
                      }
                      else{
                        this.financialsTotals.purchases += Number(res.value)
                      }

                      chartData.push(res.value)
                      this.lineChartLabels.push(tempDateString)
                      },
                      (err)=>{
                        console.log('[AppReports] [getTransactionsInfo] Error retrieving data: ', err)
                      }
                    )
                }

              // get User Info
              if(action.userId){
                this.db.object('/staging/users/' + action.userId
                  )
                  .valueChanges()
                    .subscribe(
                      (res)=>{
                        if(res){
                          action['user'] = res
                        }
                        else{
                          action['user'] = {displayName:'invalid user'}
                        }
                      },
                      (err)=>{
                        console.log('[AppReports] [getUserInfo] Error retrieving data: ', err)
                      }
                    )
              }

            }
          )
          console.log('paymentsActions', this.paymentsActions)

          this.lineChartData.push({data: chartData, label: 'Payments'})
          this.lineChartData.push( { data: [20, 5, 0, 30, 20, 15, 18, 0, 0, 15, 10, 20], label: 'Withdrawals' })

          console.log('[AppReports] [getPyamentsHistory] lineChartData: ', this.lineChartData)
          console.log('[AppReports] [getPyamentsHistory] lineChartLabels: ', this.lineChartLabels)

        },
        (err)=>{
          console.log('[AppReports] [getPyamentsHistory] Error retrieving data: ', err)
        }
      )
  }

  getTournamentInfo(tournamentId){
    let tournamentInfo
    console.log('[AppReports] [getTournamentInfo] link', '/staging/tournamentsInfo/' + tournamentId )


      return tournamentInfo
  }

  saveUserData(){
    this.db.object('/staging/users/' + this.userId).update(
      this.currentUser
    )
    .then(
      (res)=>{
        alert('User data saved');
        console.log('[Edit user]  Updated data ', res )
      }
    )
    .catch( (err) => {
           alert('Unable to save user data');
           console.log('err', err)
         } );
  }

  generatePaymentsChartData(){
    let chartData = {
      data: []
    }
    this.paymentsActions.forEach(
      (action)=>{
        let tempDate = new Date( Number(action.date_time))
        let tempDateString = moment(tempDate).format('DD-M-YY')
        console.log('action.transaction', action['transaction']['value'])
        console.log('tempDateString', tempDateString)
        if (chartData['data'][ tempDateString ]) {
          chartData['data'][ tempDateString ] += action['transaction']['value']
        }
        else{
          chartData['data'][ tempDateString ] = action['transaction']['value']
        }

      }
    )
    console.log('Chart Data', chartData)
    return chartData
  }

  changeSection(section){
    this.selectedSection= section
  }





}
