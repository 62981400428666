import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask} from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {Router, ActivatedRoute} from '@angular/router';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import * as moment from 'moment';

@Component({
  selector: 'organisation-statistics-component',
  templateUrl: './organisation.statistics.component.html',
  styleUrls: ['./organisation.statistics.component.css']
})
export class OrganisationStatisticsComponent{
  selectedSection;
  currentUserId;
  dbUser;
  currentUser;
  db;
  userId;
  startDate;
  endDate;
  tournamentsStartDate;
  tournamentsEndDate
  withdrawalsStartDate
  withdrawalsEndDate
  paymentsStartDate
  paymentsEndDate
  tournamentsActions = []
  paymentsActions = []
  withdrawalsActions = []
  actions = [];
  currentBalance;
  totals

  public lineChartData = [
  ];
  public lineChartLabels=[];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
        },
        {
          id: 'y-axis-1',
          position: 'right',
          gridLines: {
            color: 'rgba(255,0,0,0.3)',
          },
          ticks: {
            fontColor: 'red',
          }
        }
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
    { // grey
      backgroundColor: 'rgba(158,201,75,0.2)',
      borderColor: 'rgba(158,201,75,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // red
      backgroundColor: 'rgba(255,0,0,0.3)',
      borderColor: 'red',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // dark grey
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    }

  ];
  public lineChartLegend = true;
  public lineChartType = 'line';

  constructor(private angularFireDatabase: AngularFireDatabase, public afAuth: AngularFireAuth, public afStorage: AngularFireStorage,
    public router: Router, private _Activatedroute: ActivatedRoute) {
      this.db = angularFireDatabase;
      this.afAuth.authState.subscribe(res => {

          this.userId = res.uid;

          this.db.object('/staging/users/' + this.userId)
            .valueChanges()
            .subscribe(user => {
              this.currentUser = user;
            });

          this.db.object('/staging/balance/' + this.userId)
            .valueChanges()
            .subscribe(b => {
              this.currentBalance = b;
            });
      })
  }



  getTournamentsHistory(){
    if (!this.tournamentsEndDate) {
      this.tournamentsEndDate = new Date();
    }
    let endDate = new Date();
    endDate.setHours(23,59,59,0);

    this.db.list('/staging/actionsHistory/',
        ref => {
          return ref.orderByChild('date_time')
          .startAt( this.tournamentsStartDate.getTime() )
          .endAt( endDate.getTime() )
        }
    )
    .valueChanges()
      .subscribe(
        (res)=>{

          this.tournamentsActions = [];
          res.forEach(element => {
            if (element.userId === this.userId && element['action'] === 'EndTournament' ) {
              console.log('HOST', element.host)
              this.tournamentsActions.push(element)
            }
          });

          this.totals ={'markers_played':0, 'host_winnings':0, 'winners_prizes':0 }
          this.tournamentsActions.forEach(
            (action)=>{
              if(action.date_time){
                action.date_time = new Date( Number(action.date_time))
              }
              // get Tournament Info
              if(action.tournamentId){
                this.db.object('/staging/tournamentsInfo/' + action.tournamentId
                  )
                  .valueChanges()
                    .subscribe(
                      (res)=>{
                       action['tournament'] = res
                        this.totals.markers_played += res.results.totalMarkers
                        this.totals.host_winnings += res.results.hostMarkers
                        this.totals.winners_prizes += res.results.winnerMarkers
                      },
                      (err)=>{
                        console.log('[AppReports] [getTournamentInfo] Error retrieving data: ', err)
                      }
                    )
              }

              // get User Info
              if(action.userId){
                this.db.object('/staging/users/' + action.userId
                  )
                  .valueChanges()
                    .subscribe(
                      (res)=>{
                     action['user'] = res
                      },
                      (err)=>{
                        console.log('[AppReports] [getUserInfo] Error retrieving data: ', err)
                      }
                    )
              }

            }
          )
          //Sort tournaments by Date
          this.tournamentsActions.sort(function (a, b) {
            if (a.date_time > b.date_time) {
                return -1;
            }
            if (b.date_time > a.date_time) {
                return 1;
            }
            return 0;
        });
        },
        (err)=>{
          console.log('[AppReports] [getHistory] Error retrieving data: ', err)
        }
      )
  }


  getTournamentInfo(tournamentId){
    let tournamentInfo
    console.log('[AppReports] [getTournamentInfo] link', '/staging/tournamentsInfo/' + tournamentId )


      return tournamentInfo
  }

  // saveUserData(){
  //   this.db.object('/staging/users/' + this.userId).update(
  //     this.currentUser
  //   )
  //   .then(
  //     (res)=>{
  //       alert('User data saved');
  //       console.log('[Edit user]  Updated data ', res )
  //     }
  //   )
  //   .catch( (err) => {
  //          alert('Unable to save user data');
  //          console.log('err', err)
  //        } );
  // }

  generatePaymentsChartData(){
    let chartData = {
      data: []
    }
    this.paymentsActions.forEach(
      (action)=>{
        let tempDate = new Date( Number(action.date_time))
        let tempDateString = moment(tempDate).format('DD-M-YY')
        console.log('action.transaction', action['transaction']['value'])
        console.log('tempDateString', tempDateString)
        if (chartData['data'][ tempDateString ]) {
          chartData['data'][ tempDateString ] += action['transaction']['value']
        }
        else{
          chartData['data'][ tempDateString ] = action['transaction']['value']
        }

      }
    )
    console.log('Chart Data', chartData)
    return chartData
  }

}
