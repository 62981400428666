import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params }   from '@angular/router';
import { Location, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AngularFireDatabase } from '@angular/fire/database';
import {MatDialog, MatDialogRef} from '@angular/material';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/catch';

import { AddQuestionComponent } from '../add-question/add-question.component';
import { EditQuestionComponent } from '../edit-question/edit-question.component';
import { ViewQuestionComponent } from '../view-question/view-question.component';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit {
  questions;
  questionTitle
  questionsDetailed
  selectedOption: string;
  currentUser;
  currentCategory;
  currentCategoryName

  constructor(private db: AngularFireDatabase, public afAuth: AngularFireAuth, public dialog: MatDialog, private route: ActivatedRoute,
  private location: Location )
  {
    this.afAuth.authState.subscribe(res => {
      this.currentUser = res;
      // get category from route params
      this.route.params
      .subscribe(params => this.currentCategory = params['category']);// remove the first character from category name (-)
      // get the list of questions in category

      db.list('/staging/questionsBySubcategory/'+ this.currentCategory)
        .snapshotChanges()
        .subscribe(
          (res)=>{
            this.questions = []
            res.forEach(
            (question)=>{
              // console.log('[Questions Component] question ', question)
              let temp={}
              temp['value'] = question.payload.val()
              temp['$key'] = question['key']
              this.questions.push(temp)
            })
          });

      db.object('/staging/subcategories/-'+ this.currentUser.uid + '/' + this.currentCategory)
        .valueChanges()
        .subscribe(
          (r)=>{
            console.log('/staging/subcategories/-'+ this.currentUser.uid + '/' + this.currentCategory)
            console.log('Current category User', this.currentUser)
            console.log('Current category Categ', this.currentCategory)
            console.log('Current category Name', r)
            let temp = r
            console.log('Current category Name', temp)
            this.currentCategoryName = temp['subcategoryName']
        })

      // db.list('/staging/questionsBySubcategory/-'+ this.currentCategory)
      //   .snapshotChanges()
      //   .subscribe(
      //     (res)=>{
      //       this.questionTitle = []
      //       res.forEach(
      //       (question)=>{
      //         let temp = question.payload.val()
      //         temp['$key'] = question['key']
      //         this.questionTitle.push(temp)
      //       })
      //     });

      // this.questionsDetailed = db.list('/staging/qos/');
      });
  }

  ngOnInit(): void {
  }

  addQuestion() {
    let dialogRef = this.dialog.open(AddQuestionComponent);
    dialogRef.afterClosed().subscribe(newQuestion => {
      console.log('dialog closed=>', newQuestion);
      if (newQuestion) {
        this.db.list('/staging/qos/')
          .push(newQuestion)
          .then(
            (res)=>{
              console.log('Added new question', res)
              let temp = res.key
              this.db.object('/staging/questionsBySubcategory/' + this.currentCategory + '/'+ res.key)
              .set(newQuestion.title)
              .then(
                (rez)=> console.log('Added new question to category ' + this.currentCategory , rez )
              )
              .catch((err)=>{console.log('Error saving question to questionsBySubcategory' + this.currentCategory, err)})
            }
          )
          .catch((err)=>{console.log('Error saving question to ', err)})
      }
    });
  }

  viewQuestion(question) {
    let dialogRef = this.dialog.open(ViewQuestionComponent, {data: question});
    dialogRef.afterClosed().subscribe(result => {
      console.log('dialog closed=>', result);
      if (result) {
        console.log('Saving edited question', result);
        this.questions.update( question.$key, result );
      }
    });
   }

  editQuestion(questionId) {
    let dialogRef = this.dialog.open(EditQuestionComponent, {data: questionId});
    dialogRef.afterClosed().subscribe(result => {
      console.log('[editQuestion] dialog closed=>', result);
      if (result) {
        this.db.object('/staging/qos/'+questionId)
          .set(result)
          .then(
            ()=>{
              this.db.object('/staging/questionsBySubcategory/' + this.currentCategory + '/' + questionId)
              .set(result.title)
              .then(
                (rez)=> {
                  console.log('Successfully edited question  ' + questionId +  ' in ' + this.currentCategory , rez )
                  alert('Question edited successfuly')
                }
              )
              .catch((err)=>{
                alert('Error saving question. Please try again')
                console.log('ERROR editing question  ' + questionId +  ' in ' + this.currentCategory , err )
              })
            }
          )
          .catch((err)=>{console.log('Error saving question to ', err)})
      }
    });
  }

}
