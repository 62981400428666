import { Component } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import * as firebase from 'firebase/app';
import {Router} from '@angular/router';
import {TopMenuComponent} from './menus/top-menu/top-menu.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
	user: Observable<firebase.User>;
	currentUser;
  currentUserId;
  dbUser;

  constructor(public afAuth: AngularFireAuth, public router: Router, db: AngularFireDatabase) {
    this.user = afAuth.authState;
	  this.afAuth.authState.subscribe(res => {
        if (res) {
          this.currentUserId = res.uid;
          this.dbUser = db.object('/staging/users/' + res.uid);
          this.dbUser
          .valueChanges()
          .subscribe(user => {
            this.currentUser = user;
            });
        }
        else {
          console.log('user not logged in');
          this.router.navigate(['/login']);
        }
	});
  }

  ngOnInit(){
  }

  logout() {
    this.currentUser = null;
    this.afAuth.auth.signOut();
  }
}
