import { Component } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import * as firebase from 'firebase/app';
import {Router} from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent{
  user: Observable<firebase.User>;
  currentUser
  currentUserId;

  constructor(public afAuth: AngularFireAuth, public router: Router, bd: AngularFireDatabase) {
    this.user = afAuth.authState;
    this.afAuth.authState.subscribe(res => {
          if (res) {
            this.currentUserId = res.uid;
            bd.object('/staging/users/' + this.currentUserId)
            .valueChanges()
            .subscribe( rezultat =>{
              this.currentUser = rezultat;
              if (this.currentUser['userType'] === 'organization') {
                console.log('you are authorized. go ahead' + this.currentUserId );
              }
              else{
                this.router.navigate(['/login']);
                alert('You are not authorized for this! Please contact the administrator')
              }
            });
          }
        });
}

  ngOnInit() {
  }

}
