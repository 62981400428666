import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';


@Component({
  selector: 'app-quizes',
  templateUrl: './quizes.component.html',
  styleUrls: ['./quizes.component.css']
})
export class QuizesComponent implements OnInit {
  myTournaments;
	myTournamentsByHost;
  myTournamentsInfo;
  currentUser;
  myQuizList;

  constructor(public db: AngularFireDatabase, public afAuth: AngularFireAuth)
  {

  }

  ngOnInit() {
    this.afAuth.authState.subscribe(user => {
      this.currentUser = user;
      this.db.list('/staging/quizByKreator/' + this.currentUser.uid)
      .snapshotChanges()
      .subscribe(
        (res)=>{
          this.myQuizList=[]
          res.forEach(
            (question)=>{
              // console.log('[Questions Component] question ', question)
              let temp = question.payload.val()
              temp['$key'] = question['key']
              this.myQuizList.push(temp)
            })
          console.log('this.myQuizList', this.myQuizList)
        }
        )
      });
  }

  deleteQuiz(key){
    var r = confirm("Are you sure you want to delete this?");
    if (r == true) {
      this.db.object('/staging/quizByKreator/' + this.currentUser.uid + '/' + key)
       .remove()
       .then(
         (res)=>{
           this.db.object('/staging/quizLibrary/' + key)
           .remove()
           .then(
             (r)=>{
               alert('Quiz deleted successfully')
             }
           )
         }
         )
    }

  }


}
