import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import {MatDialog, MatDialogRef} from '@angular/material';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';

import { AddSubcategoryComponent } from '../add-subcategory/add-subcategory.component';
import { EditSubcategoryComponent } from '../edit-subcategory/edit-subcategory.component';

@Component({
  selector: 'app-subcategories',
  templateUrl: './subcategories.component.html',
  styleUrls: ['./subcategories.component.css']
})
export class SubcategoriesComponent implements OnInit {
	subcategories;
  selectedOption: string;
  currentUser;
  database;

  constructor(db: AngularFireDatabase, public afAuth: AngularFireAuth, public dialog: MatDialog )
  {
    this.database = db;
    this.afAuth.authState.subscribe(res => {
      this.currentUser = res;
      db.list('/staging/subcategories/-'+ this.currentUser.uid)
       .snapshotChanges()
        .subscribe(
          (res)=>{
            console.log('[Add Quiz] categoryChange - My subcategories ', res )
            this.subcategories = []
            res.forEach(
            (categ)=>{
              let temp = categ.payload.val()
              temp['$key'] = categ['key']
              this.subcategories.push(temp)
            })
          });

      });
  }

  ngOnInit() {
  }

  addSubcategory() {
    let dialogRef = this.dialog.open(AddSubcategoryComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedOption = result;
        this.database.list('/staging/subcategories/-'+ this.currentUser.uid)
        .push(
          { subcategoryName: result }
        )
        .then(
          alert('Subcategory saved!')
        )
        .catch(
          (err)=>{
            alert('Something went wrong! ' + err)
          }
        )
      }
    });
  }

  editSubcategory(subcategory) {
    let dialogRef = this.dialog.open(EditSubcategoryComponent, {data: subcategory});
    dialogRef.afterClosed().subscribe(result => {
      console.log('edited', subcategory, result)
      if (result) {
        this.database.object('/staging/subcategories/-'+ this.currentUser.uid+'/'+subcategory.$key)
          .set({subcategoryName: result})
      }
    });
  }

}
